<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <!-- <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
          size="50"
        > 
                  <v-img src="Dar Diwan World App - Icon.svg" max-height="30" />
        </v-list-item-avatar>
        -->
        <v-list-item-avatar color="white" contain>
          <v-img src="Dar Diwan World App - Icon.svg" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="display-1" v-text="profile.title" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav v-if="role == 1">
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <div />
    </v-list>
    <v-list expand nav v-else-if="role == 5">
      <div />

      <template v-for="(item, i) in computedItems1">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <div />
    </v-list>
    <v-list expand nav v-else> </v-list>

    <!-- <v-list expand nav>
      <v-list-group :value="true" no-action sub-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="([title, icon], i) in admins" :key="i" link>
          <v-list-item-icon>
            <v-icon v-text="icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="title" />
        </v-list-item>
      </v-list-group>
    </v-list> -->
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";
import ApiService from "@/services/api.service";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "dashboard",
        to: "/",
      },
      {
        icon: "mdi-school",
        title: "Users",
        children: [
          {
            icon: "mdi-account",
            title: "User",
            to: "pages/user",
          },
          {
            icon: "mdi-account",
            title: "Add",
            to: "add",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "Products",
        children: [
          {
            icon: "mdi-cog",
            title: "Product",
            to: "product",
          },
          {
            icon: "mdi-cog",
            title: "Authors",
            to: "authors",
          },
          {
            icon: "mdi-cog",
            title: "Publishers",
            to: "publishers",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "Celebrity",
        children: [
          {
            icon: "mdi-cog",
            title: "Celebrity",
            to: "celebrity",
          },
          {
            icon: "mdi-cog",
            title: "Celebrities Favourities",
            to: "celebrity-favourity",
          },
          {
            icon: "mdi-cog",
            title: "Celebrities Products",
            to: "celebrity-product",
          },
        ],
      },
      {
        icon: "mdi-cog",
        title: "Categories",
        children: [
          {
            icon: "mdi-cog",
            title: "Main Categories",
            to: "main-categories",
          },
          {
            icon: "mdi-cog",
            title: "Sub Categories",
            to: "categories",
          },
          {
            icon: "mdi-cog",
            title: "Library Categories",
            to: "library-categories",
          },
          {
            icon: "mdi-cog",
            title: "Tags",
            to: "tags",
          },
          {
            icon: "mdi-cog",
            title: "Book Types",
            to: "book-types",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "Coupons",
        children: [
          {
            icon: "mdi-cog",
            title: "Coupons",
            to: "coupons",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "Package",
        children: [
          {
            icon: "mdi-cog",
            title: "Packages",
            to: "package",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "Orders",
        children: [
          {
            icon: "mdi-cog",
            title: "Reviews",
            to: "reviews",
          },
          {
            icon: "mdi-cog",
            title: "Support",
            to: "support",
          },
          {
            icon: "mdi-cog",
            title: "Notify Me",
            to: "notify",
          },
          {
            icon: "mdi-cog",
            title: "Order Status",
            to: "order-status",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "Banners",
        children: [
          {
            icon: "mdi-cog",
            title: "Banners",
            to: "banners",
          },
        ],
      },
      {
        icon: "mdi-cog",
        title: "Settings",
        children: [
          {
            icon: "mdi-cog",
            title: "Attributes",
            to: "attributes",
          },
          {
            icon: "mdi-cog",
            title: "Attribute Value",
            to: "attribute-value",
          },
          {
            icon: "mdi-cog",
            title: "Brands",
            to: "brands",
          },
          {
            icon: "mdi-cog",
            title: "Languages",
            to: "languages",
          },
          {
            icon: "mdi-cog",
            title: "Currencies",
            to: "currencies",
          },
          {
            icon: "mdi-cog",
            title: "Countries",
            to: "countries",
          },
        ],
      },
      {
        title: "Book fair",
        icon: "mdi-bell",
        to: "invoice",
      },
      // {
      //   icon: "mdi-timeline",
      //   title: "timeline",
      //   to: "/timeline",
      // },
    ],
    items1: [
      {
        icon: "mdi-view-dashboard",
        title: "dashboard",
        to: "/",
      },
      {
        title: "Book fair",
        icon: "mdi-bell",
        to: "invoice",
      },
      // {
      //   icon: "mdi-timeline",
      //   title: "timeline",
      //   to: "/timeline",
      // },
    ],
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
      ["Settings", "mdi-cog-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    role: null,
  }),

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    computedItems1() {
      return this.items1.map(this.mapItem);
    },
    computedItems2() {
      return null;
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },
  async created() {
    let info = await JSON.parse(ApiService.getUserInfo());
    this.role = info.role;
    console.log(info.role);
  },
  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
